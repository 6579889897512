@import "../../../colours";
@import "../../../dimensions";

.form-view-component {

  .section-title {
    display: block;
    text-align: center;
    font-size: x-large;
    font-weight: 800;
    margin-bottom: 15px;
  }

  .common-value-wrapper {
    display: block;
    background: $grey-xlight;
    border-radius: $pill-border-radius;
    padding: $pill-padding-vertical $pill-padding-horizontal;
  }

  .form-question-wrapper {
    margin-bottom: 10px;
  }

  .form-label-component {
    label {
      display: block;
    }
  }

  .form-text-component {
    label {
      display: block;
    }
  }

  .form-radio-input {
    label {
      display: block;
      padding: 5px 0;
    }
  }

  .form-signature-component {
    .signature-input {
      border: 1px solid $grey-light;
      border-radius: 5px;
    }
  }

  .form-photos-component {
    .photo-thumbs {
      display: block;


      .image-thumbnail {
        width: 120px;
        height: 120px;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }


}