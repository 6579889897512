@import "../../colours";

.tokyo-emerging-button {
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: $white;
    box-shadow: 0 0 12px $shadow-faint;
    z-index: 39;
    border-radius: 5px;
    opacity: 0;
  }

  &:hover {
    &:before {
      animation-name: backing-did-appear;
      animation-duration: 250ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
  }

  &.pill {
    &:before {
      border-radius: 9999px;
    }
  }

  .content-wrapper {
    z-index: 40;
    position: relative;
  }

  @keyframes backing-did-appear {
    0% { opacity: 0; transform: rotateX(30deg) }
    25% { opacity: 0.8; transform: rotateX(30deg) }
    100% { opacity : 1; transform: rotateX(0deg) }
  }
}